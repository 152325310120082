import { Link, graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { iconWeb } from '../components/icon-web'
import PlayUrlContext from "~/context/play-url"

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 90px;
  z-index: 20;
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
  }

  .content {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 70px;
    bottom: 0;
    background: var(--color-forth);
    box-shadow: 0px 3px 16px #8100BE26;
    text-align: center;
    padding-bottom: 10px;
    border-radius: 10px 10px 0px 0px;

    a {
      display: block;
      height: 50px;
    }
  }

  .menu-icon {
    display: block;

    &.home {
      width: 40px;
      height: 40px;
    }

    &.game {
      margin: auto;
      width: 94px;
      height: 94px;
      transform: translate(0px, -30px);
    }

    &.news {
      width: 40px;
      height: 40px;
    }
  }
`

export default function FooterMobile({ langKey }) {
  const data = useStaticQuery(graphql`
  query {
    allMarkdownRemark(filter: {collection: {eq: "common"}}) {
      edges {
        node {
          file
          frontmatter {
            navhome
            navevent
            navgames
          }
        }
      }
    }
  }
`)
  const {
    node: { frontmatter },
  } = data.allMarkdownRemark.edges
    .find(item => item.node.file
      .split(".")[1]
      .replace("/", "") === langKey)

  const { themeLanding, logoWeb } = React.useContext(PlayUrlContext);

  const menuListPath = [
    { path: "/", key: "index", icon: "home", textVal: "navhome", },
    { path: "/game", key: "game", icon: "game", textVal: "navgames", },
    { path: "/event", key: "event", icon: "news", textVal: "navevent", },
  ]

  const homeActive = {
    background: `url(${iconWeb[themeLanding].home.active}) no-repeat`,
  }

  const newsActive = {
    background: `url(${iconWeb[themeLanding].news.active}) no-repeat`,
  }

  return (
    <Wrapper>
      <div className='content'>
        {menuListPath.map((items, index) => (
          <div key={index}>
            {items.icon === 'home' && (
              <Link to={`${langKey === "en" ? `${items.path}` : `/${langKey}${items.path}`}`}
                activeStyle={homeActive}
              >
                <img src={iconWeb[themeLanding][items.icon].normal} className={`menu-icon ${items.icon}`} alt={items.icon} />
                {frontmatter[items.textVal]}
              </Link>
            )}
            {items.icon === 'news' && (
              <Link to={`${langKey === "en" ? `${items.path}` : `/${langKey}${items.path}`}`}
                activeStyle={newsActive}
              >
                <img src={iconWeb[themeLanding][items.icon].normal} className={`menu-icon ${items.icon}`} alt={items.icon} />
                {frontmatter[items.textVal]}
              </Link>
            )}
            {items.icon === 'game' && (
              <Link to={`${langKey === "en" ? `${items.path}` : `/${langKey}${items.path}`}`}
              >
                <img src={iconWeb[themeLanding][items.icon].normal} className={`menu-icon ${items.icon}`} alt={items.icon} />
                {frontmatter[items.textVal]}
              </Link>
            )}
          </div>
        ))}
      </div>
    </Wrapper>
  )
}