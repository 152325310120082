import React, { useEffect, useState } from "react"
import styled from 'styled-components';
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
import { FetchGame } from '../fetchers/game';

import Header from "~/components/header"
import Support from "~/components/support"
import PlayUrlContext from "~/context/play-url"

import GlobalStyle from "~/styles/js/global"
import SliderBar from './silde-bar';
import SearchComponent from './search-box';
import SidebarMobile from './sildebar-mobile';
import FooterMobile from './footer-mobile';
import Footer from './footer';

const Wrapper = styled.div`
  margin-top: 60px;
  transition: margin-left 0.2s linear;

  @media screen and (max-width: 768px) {
    margin-top: 0px;
    margin-left: 0px !important;
  }
`

const Layout = ({ children, pageContext, varFilter }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          languages {
            defaultLangKey
            langs
          }
        }
      },

      iconWeb: allMarkdownRemark(filter: {collection: {eq: "icons"}}) {
        edges {
          node {
            id
            frontmatter {
              question
              sort
              sun
              gift
              play
            }
          }
        }
      }
    }
  `)

  const iconWeb = data.iconWeb.edges[0].node.frontmatter;
  const url = pageContext.slug
  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, '/')
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url))
    .map((item) => ({ ...item, link: item.link.replace(`/${defaultLangKey}/`, '/') }))
  const { isShowMenu, isSearch, setGameData, updateCategories, centralList, webConfig } = React.useContext(PlayUrlContext)
  const [toggleSupport, setToggleSupport] = useState(false)

  useEffect(() => {
    if (centralList.api !== '') {
      const fetchGame = async () => {
        const gameFilter = ['DARTSFEST', 'LEGENDOFDOLLY', 'MJPGT'];
        const providerFilter = ['AMBSLOT']
        // const response = await FetchGame(centralList.api);
        const res = centralList.gameList.map(item => ({
          image: item.image,
          name: item.name,
          kind: item.kind,
          partner: item.partner,
          status: item.status,
          type: item.type,
          tag: item.tag,
          prefix: item.prefix,
          url: item.url,
          demoUrl: item.demoUrl
        })).filter((items) => !gameFilter.includes(items.prefix) && providerFilter.includes(items.partner))
        setGameData(res)
        const type = res.map(item => item.type)
        const mapCate = type
          .filter((v, i) => type.indexOf(v) === i)
          .map(item => ({
            text: item,
            icon: item,
          }))
        updateCategories([
          {
            text: "All Games",
            icon: "allgames",
          },
          ...mapCate,
        ])
      }
      fetchGame()
    }
  }, [centralList.api])

  const handleSupport = (val) => {
    setToggleSupport(val)
  }
  return (
    <PlayUrlContext.Consumer>
      {({ playUrl }) => (
        <div className="relative">
          <GlobalStyle
            isSearch={isSearch}
            primary={webConfig && webConfig.color.primary}
            secondary={webConfig && webConfig.color.secondary}
            third={webConfig && webConfig.color.third}
            forth={webConfig && webConfig.color.forth}
            main={webConfig && webConfig.color.main}
            darkMode={webConfig && webConfig.color.darkMode}
            secondaryMain={webConfig && webConfig.color.secondaryMain}
          />
          <Header
            siteName={`Strapi`}
            playUrl={playUrl}
            langKey={pageContext.langKey}
            langsMenu={langsMenu}
            handleSupport={handleSupport}
            iconWeb={iconWeb}
            logoWeb={webConfig && webConfig.logoWeb}
            lobbyUrl={centralList.lobbyUrl} />
          {toggleSupport && <Support
            langKey={pageContext.langKey}
            handleSupport={handleSupport} />}
          <SliderBar
            langKey={pageContext.langKey}
            varFilter={varFilter}
          />
          <SidebarMobile
            langsMenu={langsMenu}
            langKey={pageContext.langKey}
          />
          <SearchComponent
            langKey={pageContext.langKey}
          />
          <Wrapper
            className="flex flex-col min-h-screen"
            style={{
              marginLeft: `${isShowMenu ? 'var(--sidebar-width)' : 'var(--sidebar-width-inactive'}`
            }}
          >
            <main className="container flex-1 mx-auto">{children}</main>
            <Footer langKey={pageContext.langKey} logoWeb={webConfig && webConfig.logoWeb} />
          </Wrapper>
          <FooterMobile langKey={pageContext.langKey} />
        </div>
      )}
    </PlayUrlContext.Consumer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
